import React, {Component} from 'react';
import './Login.css'

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {userName: "", password: "", errorMessage: ""}
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({errorMessage: ""});
    let userName = this.state.userName.trim().toLowerCase();
    let password = this.state.password.trim();
    if (userName === null || userName === "") {
      this.setState({errorMessage: "Bitte gib einen Benutzernamen ein"});
      return;
    }
    if (password === null || password === "") {
      this.setState({errorMessage: "Bitte gib ein Passwort ein"});
      return;
    }

    const md5 = require('md5');
    //c.jones
    //goldenerelefant2022
    if (md5(userName) !== "6be51ad71ad87b1ae9d215111a955f7f" || md5(password)
        !== "40b03a31900844147dbcd6eb004813c0") {
      this.setState(
          {errorMessage: "Der Benutzername oder das Passwort ist falsch"});
      return;
    }

    this.props.onLogin(true);

  }

  render() {
    let errorMessageDisplay = this.state.errorMessage === null
    || this.state.errorMessage === "" ? "none" : "block"
    return <div className="loginWrapper">
      <form onSubmit={this.handleSubmit}>
        <input name="userName" type="text" placeholder="Benutzername"
               onChange={this.handleInputChange}/>
        <input name="password" type="password" placeholder="Passwort"
               onChange={this.handleInputChange}/>
        <p style={{display: errorMessageDisplay}}>{this.state.errorMessage}</p>
        <input type="submit" value="Login"/>

      </form>
    </div>;
  }

}

export default Login;